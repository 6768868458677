import React from 'react'
import { Block, Body, BodyLarge, BodySmall, H3, media, Row } from '@thesisedu/web/dist'
import { Col } from 'antd'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { OutboundLink } from 'gatsby-plugin-google-analytics'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import lightLogo from '../images/academy-light-logo.svg'
import { Container } from './styled'
import { FRONTEND } from '../constants'
import { FreeTrialButton } from './FreeTrialButton'
import { StudentLoginLink } from './StudentLoginLink'

const LinkList = styled.ul`
  list-style: none none;
  padding: 0;
  margin-top: ${(props) => props.theme['@size-m']};
  margin-bottom: 50px;
  ${media.sm} {
    margin-bottom: 50px;
  }
  li {
    margin-bottom: 12px;
  }
  a {
    color: ${(props) => props.theme['@text-color']};
  }
`
const Copyright = styled(BodySmall)`
  color: ${(props) => props.theme['@gray-6']};
  text-transform: uppercase;
  font-size: 10px !important;
  letter-spacing: 0.5px;
  line-height: 1;
  font-weight: bold;
`

export const Footer: React.FC = () => {
  return (
    <OuterContainer>
      <Container>
        <Row gutter={{ sm: 15, md: 30 }}>
          <Col xs={24} md={12} lg={8}>
            <img style={{ height: '40px' }} src={lightLogo} alt={'Prodigies Academy'} />
            <Block marginTop={'@size-m'} marginBottom={'@size-m'}>
              <Body>
                <strong>Where every child is exceptional.</strong> Engaging, interactive,
                standards-based, customizable curriculum for K-5 General Music Education.
              </Body>
            </Block>
          </Col>
          <Col xs={0} lg={2} />
          <Col xs={12} md={6} lg={4}>
            <H3>Company</H3>
            <LinkList>
              <li>
                <AnchorLink to={'/#lessons'}>Lessons</AnchorLink>
              </li>
              <li>
                <AnchorLink to={'/#platform'}>Platform</AnchorLink>
              </li>
              <li>
                <Link to={'/learn'}>Learn</Link>
              </li>
              <li>
                <Link to={'/contact'}>Contact</Link>
              </li>
            </LinkList>
          </Col>
          <Col xs={12} md={6} lg={4}>
            <H3>My Account</H3>
            <LinkList>
              <li>
                <StudentLoginLink />
              </li>
              <li>
                <OutboundLink href={`${FRONTEND}/auth/login`}>Teacher Login</OutboundLink>
              </li>
              <li>
                <Link to={'/terms-of-use'}>Terms of Use</Link>
              </li>
              <li>
                <Link to={'/privacy'}>Privacy Policy</Link>
              </li>
              <li>
                <Link to={'/accessibility'}>Accessibility</Link>
              </li>
            </LinkList>
          </Col>
          <Col xs={24} lg={6}>
            <BodyLarge isBlock>
              Improve student engagement with our standards-based and customizable curriculum by
              signing up for a free trial today!
            </BodyLarge>
            <Block marginBottom={'@size-xs'}>
              <FreeTrialButton />
            </Block>
          </Col>
        </Row>
        <Block marginTop={'@size-s'} style={{ paddingBottom: 48 }}>
          <Row align={'middle'}>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Copyright>
                Copyright &copy; {new Date().getFullYear()} Prodigies Academy LLC
              </Copyright>
            </Col>
            <Col xs={0} md={8} lg={12} />
            <PoweredBy xs={24} sm={12} md={8} lg={6} />
          </Row>
        </Block>
      </Container>
    </OuterContainer>
  )
}

const OuterContainer = styled.div`
  background: ${(props) => props.theme['@background-color-base']};
  margin-top: ${(props) => props.theme['@size-section-md']};
  padding: ${(props) => props.theme['@size-xxl']} 0;
`
const PoweredBy = styled(Col)`
  ${media.sm} {
    text-align: right;
  }
  img {
    height: 14px;
  }
`
