import React from 'react'
import { ButtonProps } from 'antd/es/button'
import { FRONTEND } from '../constants'
import { GlowButton } from './styled'

export const FreeTrialButton: React.FC<ButtonProps> = (props) => (
  <GlowButton
    type={'primary'}
    style={{ width: 200 }}
    children={'Start your Free Trial'}
    {...props}
    onClick={() => {
      window.location.href = `${FRONTEND}/auth/trial-request`
    }}
  />
)
